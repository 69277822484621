<template>
  <app-module-view>
    <template slot="body">
      <app-preloader-full v-if="callingAPI"></app-preloader-full>
      <app-kpi-settings-filter></app-kpi-settings-filter>
      <div class="card" v-if="kpi.departmentId > 0">
        <div class="card-header">
          {{ $t('kpi.kpi_settings_department') }}
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-striped align-baseline">
              <thead>
              <tr>
                <th style="width: 200px"></th>
                <th v-for="(kpiParameter, index) in kpiParameters" :key="`item-${index}`">
                  {{ $t('kpi.kpi_parameters.' + kpiParameter.id) }}
                  <span v-if="kpiParameter.id === 'pageViews' && pageViewsPerPage > 0">
                    <br>{{ $t('kpi.suggestedValue') }}:
                    <app-tooltip-slot
                      :title="$t('kpi.toolTip.suggestedValue')"
                      position="absolute"
                    >
                      <a class="text-button"
                         @click="setSuggestedPageViews()">
                          {{ getSuggestedPageViews() | formatNumber }}
                      </a>
                  </app-tooltip-slot>
                  </span>
                  <span v-if="kpiParameter.id === 'videoViews' && videoViewsPerVideo > 0">
                    <br>{{ $t('kpi.suggestedValue') }}:
                    <app-tooltip-slot
                        :title="$t('kpi.toolTip.suggestedValue')"
                        position="absolute"
                    >
                      <a class="text-button"
                         @click="setSuggestedVideoViews()">
                          {{ getSuggestedVideoViews() | formatNumber }}
                      </a>
                  </app-tooltip-slot>
                  </span>
                </th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>{{ $t('kpi.department_overall') }}</td>
                <td v-for="(kpiParameter, index) in kpiParameters" :key="`item-${index}`" style="text-align: center">
                  <app-input
                    v-model="kpi.departmentKpi[kpiParameter.id]"
                    :name="kpiParameter.id"
                    :step="1"
                    :format-as-number="true"
                  >
                  </app-input>
                  <strong class="text-danger" v-if="overkillKpi[kpiParameter.id] > 0">
                    {{ $t('kpi.add') }}: {{ overkillKpi[kpiParameter.id] | formatNumber }}
                  </strong>
                  <strong class="text-danger" v-if="overkillKpi[kpiParameter.id] < 0">
                    {{ $t('kpi.remove') }}: {{ Math.abs(overkillKpi[kpiParameter.id]) | formatNumber }}
                  </strong>
                </td>
              </tr>
              <tr>
                <td colspan="9">
                  <hr>
                </td>
              </tr>
              <tr v-for="user in kpi.usersKpi" :key="user.id">
                <td style="vertical-align: middle">
                  {{ getUserNameByUserKpi(user) }}
                  <br>
                  <button
                    @click="setBasedOnAverage(user)"
                    type="button"
                    class="btn btn-secondary btn-sm py-0"
                  >
                    {{ $t('kpi.byAverage') }}
                  </button>
                </td>
                <td v-for="(kpiValue, kpiName) in user.userKpi" :key="user.id + kpiName">
                  <app-input
                    v-model="user.userKpi[kpiName]"
                    :format-as-number="true"
                  >
                  </app-input>
                </td>
              </tr>
              <tr>
                <td colspan="9" style="text-align: center">
                  <button v-if="isSavePossible && isSaveButtonVisible"
                          class="btn btn-success pull-right m-t-30"
                          @click.prevent="save"
                  >{{ $t('kpi.save') }}
                  </button>
                  <strong class="text-danger" id="errorMsg" v-else>{{ $t('kpi.sumError') }}</strong>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import Input from '../form/inputs/Input'
import ModuleView from '../ModuleView'
import AppKpiSettingsFilter from './KpiSettingsFilter'
import UserService from '../../services/user/UserService'
import PerformanceKpi from '../../model/PerformanceKpi'
import NotifyService from '../../services/NotifyService'
import { AVAILABLE_KPI_PARAMETERS } from '../../model/ValueObject/KpiParameters'
import { mapState } from 'vuex'
import PreloaderFull from '../preloader/PreloaderFull'
import TooltipSlot from '../tooltip/TooltipSlot'
import PermissionService from '@/services/PermissionService'

const PAGEVIEWS_SUGGESTION_MULTIPLIER = 1.2
const VIDEOVIEWS_SUGGESTION_MULTIPLIER = 1.1

export default {
  name: 'KpiSettings',
  data () {
    return {
      departmentKpi: this._.cloneDeep(PerformanceKpi),
      overkillKpi: this._.cloneDeep(PerformanceKpi),
      kpiParameters: AVAILABLE_KPI_PARAMETERS,
      isSavePossible: true,
      suggestedPageViews: 0,
      suggestedVideoViews: 0
    }
  },
  watch: {
    'kpi.departmentKpi': {
      deep: true,
      handler (param) {
        this.checkDepartmentKpiSum()
      }
    },
    'kpi.usersKpi': {
      deep: true,
      handler (param) {
        this.checkDepartmentKpiSum()
      }
    }
  },
  methods: {
    setBasedOnAverage (user) {
      user.userKpi.pageViews = Math.round(user.userKpi.articles * this.pageViewsPerPage * PAGEVIEWS_SUGGESTION_MULTIPLIER)
      user.userKpi.videoViews = Math.round(user.userKpi.videos * this.videoViewsPerVideo * VIDEOVIEWS_SUGGESTION_MULTIPLIER)
    },
    setSuggestedPageViews () {
      this.kpi.departmentKpi.pageViews = this.getSuggestedPageViews()
    },
    getSuggestedPageViews () {
      return Math.round(this.pageViewsPerPage * this.kpi.departmentKpi.articles * PAGEVIEWS_SUGGESTION_MULTIPLIER)
    },
    setSuggestedVideoViews () {
      this.kpi.departmentKpi.videoViews = this.getSuggestedVideoViews()
    },
    getSuggestedVideoViews () {
      return Math.round(this.videoViewsPerVideo * this.kpi.departmentKpi.videos * VIDEOVIEWS_SUGGESTION_MULTIPLIER)
    },
    getUserNameByUserKpi (user) {
      const freshUser = this.$store.getters['user/userById'](user.userId)
      if (freshUser) {
        user.userName = UserService.getUserInfo(freshUser)
      }
      return user.userName
    },
    checkDepartmentKpiSum () {
      this.isSavePossible = true
      for (const [key, originalValue] of Object.entries(this.kpi.departmentKpi)) {
        var newValue = 0
        this.overkillKpi[key] = 0
        this.kpi.usersKpi.forEach(user => {
          newValue += parseInt(user.userKpi[key] ? user.userKpi[key] : 0)
        })
        if (newValue !== parseInt(originalValue) && !this.requiredRecalculationIds.includes(key)) {
          this.overkillKpi[key] = originalValue - newValue
          this.isSavePossible = false
        }
      }
    },
    async save () {
      this.$store.dispatch('kpi/create')
        .then(() => {
          if (this.$store.getters['kpi/error'] === null) {
            this.$store.dispatch('kpi/fetch')
            NotifyService.setSuccessMessage(this.$t('notify.record_was_updated'))
          } else {
            NotifyService.setErrorMessage(this.$store.getters['kpi/error'])
          }
        })
        .catch(error => console.log(error))
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    isSaveButtonVisible () {
      const permission = PermissionService.REQUIRED_PERMISSIONS.EDITOR_PERFORMANCE_KPI_SETTINGS_PERMISSIONS.saveButton
      return this.$store.getters['user/hasPermission'](permission)
    },
    requiredRecalculationIds () {
      return this.kpiParameters.filter(parameter => parameter.dataStatus === 'requiresRecalculation')
        .map(parameter => parameter.id)
    },
    pageViewsPerPage () {
      return this.$store.getters['kpi/suggestedPageViews']
    },
    videoViewsPerVideo () {
      return this.$store.getters['kpi/suggestedVideoViews']
    },
    filter () {
      return this.$store.getters['kpi/filter']
    },
    kpi () {
      return this.$store.getters['kpi/kpi']
    }
  },
  components: {
    appInput: Input,
    appModuleView: ModuleView,
    appKpiSettingsFilter: AppKpiSettingsFilter,
    appPreloaderFull: PreloaderFull,
    appTooltipSlot: TooltipSlot
  }
}
</script>

<style lang="scss" scoped>
th span {
  font-weight: normal;
}
.text-button {
  color: #1e88e5 !important;
  cursor: pointer;
}
</style>
