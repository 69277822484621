<template>
  <section>
    <div class="card">
      <div class="card-body">
        <form @submit.prevent="filterAll">
          <div class="row">
            <div class="col-lg-2 col-md-6">
              <app-select
                v-model="filter.department"
                :options="departments"
                optionTitle="name"
                :convertValue="false"
                required
                id="kpiFilter_department"
                :label="$t('menu.departments')"
              >
              </app-select>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-date-picker v-if="filter.product || filter.department"
                               v-model="seedDate"
                               :label="$t('kpi.month')"
                               id="month_seed_date"
                               customDateFormat="Z"
                               type="month"
              ></app-date-picker>
            </div>
            <div class="col-lg-2">
              <button
                v-if="filter.department"
                class="btn btn-success pull-right m-t-30"
                @click.prevent="filterAll"
              >{{ $t('beUser.filter.search') }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>
<script>
import Select from '../form/select/Select'
import KpiSettingsFilter from '../../model/KpiSettingsFilter'
import DatePicker from '../form/Datepicker'
import moment from 'moment'
import { DISABLED_DEPARTMENTS } from '../../model/ValueObject/DisabledDepartments'

export default {
  name: 'KpiSettingsFilter',
  data () {
    return {
      filter: this._.cloneDeep(KpiSettingsFilter),
      seedDate: moment().toISOString(),
      filterOutDepartments: DISABLED_DEPARTMENTS
    }
  },
  components: {
    appSelect: Select,
    appDatePicker: DatePicker
  },
  computed: {
    departments () {
      return this.$store.getters['department/all'].filter(function (department) {
        return this.filterOutDepartments.indexOf(department.id) < 0
      }, this)
    }
  },
  watch: {
    seedDate: function (seedDate) {
      this.filter.monthYear = moment(seedDate).format('M-YYYY')
    }
  },
  methods: {
    getDepartments () {
      this.$store.dispatch('department/fetchAll')
    },
    filterAll () {
      this.filter.departmentUsers = this.departments.find(obj => obj.id === parseInt(this.filter.department)).users
      this.$store.commit('kpi/setFilter', this.filter)
      this.$store.dispatch('kpi/fetch')
    }
  },
  beforeMount () {
    this.filter = this.$store.getters['kpi/filter']
  },
  mounted () {
    this.getDepartments()
  }
}
</script>
